<template>
  <a-modal
    v-model="addBudgetVisible"
    :title="(isAdd ? '新建' : '编辑') + '合同'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="1100px"
  >
    <div class="budget-modal-container">
      <a-form-model
        ref="ruleForm"
        :model="formquery"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }">
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="部署平台" :rules="[{ required: true, message: '请选择部署平台', trigger: 'blur' }]" prop="partnerIdList">
              <m-select-more
                class="search_item"
                v-model="formquery.partnerIdList"
                :allData="partnerIdList"
                :searchById="false"
                :hasIcon="false"
                :showId="false"
                label="部署平台"
                :showSelectedPart="true"
                width="450px"
                @change="changePartnerIdList"
                :canSelectAll="true"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              label="公司名称"
              :rules="[{ required: true, message: '请选择公司名称', trigger: 'blur' }]"
              prop="trafficUserId"
            >
              <a-select
                class="search_item"
                placeholder="请选择公司名称"
                :filter-option="filterOption"
                showSearch
                v-model="formquery.trafficUserId"
                @change="changeTrafficUserId"
              >
                <a-select-option
                  v-for="item in trafficUserList"
                  :key="item.id"
                  :value="item.trafficUserId"
                  :email="item.email"
                  :mobile="item.mobile"
                  :contact="item.contact">{{ item.company }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :rules="[{ required: true, message: '请选择合同期限', trigger: 'blur' }]"
              label="合同期限"
              prop="dateEnd"
            >
              <a-range-picker
                style="width: 210px;"
                format="YYYY-MM-DD"
                :placeholder="['开始时间', '结束时间']"
                @change="ChangeDate"
                :value="rangePickerDate"
                :allowClear="false"
                :ranges="{
                  今日: [initDate(0, 0).start, initDate(0, 0).end],
                  昨日: [initDate(1, 1).start, initDate(1, 1).end],
                  最近一周: [initDate(6, 0).start, initDate(6, 0).end],
                  最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
                }"
              >
              </a-range-picker>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item
              :rules="[{ required: true, message: '请输入联系人', trigger: 'blur' }]"
              label="联系人"
              prop="contact"
            >
              <a-input v-model.trim="formquery.contact" placeholder="请输入联系人" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :rules="[
                {
                  required: true,
                  message: '请填写联系方式',
                  trigger: 'blur'
                }
              ]"
              label="联系方式"
              prop="mobile"
            >
              <a-input v-model.trim="formquery.mobile" placeholder="请输入联系方式" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :rules="[{ required: true, message: '请输登录邮箱', trigger: 'blur' }]"
              label="登录邮箱"
              prop="email"
            >
              <a-input v-model.trim="formquery.email" placeholder="请输入登录邮箱" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item
              :rules="[{ required: true, message: '请输入合同编号', trigger: 'blur' }]"
              label="合同编号"
              prop="contractId"
            >
              <a-input v-model.trim="formquery.contractId" placeholder="请输入合同编号" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <a-divider class="divider" dashed />
      <div class="edit-wrapper" v-if="!isAdd">
        <el-table :data="editList" border style="width: 96%; margin: 2%;">
          <el-table-column align="left" prop="dspName" label="广告源名称"> </el-table-column>
          <el-table-column align="left" prop="platAccountName" label="所属账户"> </el-table-column>
          <el-table-column align="left" prop="platName" label="广告平台"> </el-table-column>
          <template slot="empty">
            <m-empty style="height: 341px" imgHeight="176px">
              <template #description>暂时没有任何数据</template>
            </m-empty>
          </template>
        </el-table>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { getTrafficUserInfo } from '@/apiForManage/common'
import { addContract, updateContract, getContractById } from '@/apiForManage/mediaAccount'
import { partnerIdList } from '@/data/dataList'
import mixDate from '@/mixins/initDate'
export default {
  mixins: [mixDate],

  data () {
    return {
      formquery: {
        email: '',
        contact: '',
        mobile: '',
        dateStart: '',
        dateEnd: '',
        contractId: '',
        trafficUserId: undefined
      },
      rangePickerDate: [],
      // 回显列表
      editList: [],
      // 公司option
      trafficUserList: [],
      partnerIdList
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    budgetInfo: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    budgetInfo: {
      handler (val) {
        if (!this.isAdd) {
          this.getContractById()
        }
      },
      deep: true,
      immediate: true
    },
    visible: {
      handler (val) {
        if (val) {
        }
      }
    }
  },
  computed: {
    addBudgetVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  async created () {
    await this.getTrafficUserList()
  },
  methods: {
    changePartnerIdList () {
      this.formquery.trafficUserId = ''
      this.getTrafficUserList()
    },
    ChangeDate (date, dateString) {
      this.formquery.dateEnd = dateString[1]
      this.formquery.dateStart = dateString[0]
      this.rangePickerDate = date
    },
    // 公司option
    async getTrafficUserList () {
      const obj = {
        partnerIdList: this.formquery.partnerIdList
      }
      const { data = [] } = await getTrafficUserInfo(obj)
      this.trafficUserList = data
    },
    changeTrafficUserId (data, option) {
      this.formquery.email = option.data.attrs.email
      this.formquery.contact = option.data.attrs.contact
      this.formquery.mobile = option.data.attrs.mobile
    },
    // 根据ID查询合同信息及列表
    async getContractById () {
      const { code, data = [] } = await getContractById({
        id: this.budgetInfo.id,
        contractId: this.budgetInfo.contractId
      })
      if (code === 200) {
        this.formquery.contact = data.contact
        this.formquery.email = data.email
        this.formquery.mobile = data.mobile
        this.formquery.trafficUserId = data.trafficUserId
        this.formquery.contractId = data.contractId
        this.formquery.dateEnd = data.dateEnd
        this.formquery.dateStart = data.dateStart
        this.rangePickerDate = [data.dateStart, data.dateEnd]
        this.formquery.partnerIdList = [String(data.partner)]
        this.editList = data.dspList
      }
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleCancel () {
      this.addBudgetVisible = false
    },
    // 提交
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const query = {
            id: this.budgetInfo.id,
            trafficUserId: this.formquery.trafficUserId,
            contractId: this.formquery.contractId,
            dateStart: this.formquery.dateStart,
            dateEnd: this.formquery.dateEnd,
            email: this.formquery.email,
            mobile: this.formquery.mobile,
            contact: this.formquery.contact
          }
          if (this.isAdd) {
            const resp = await addContract(query)
            if (resp.code === 200) {
              this.addBudgetVisible = false
              this.$emit('handleChange')
            }
          } else {
            const resp = await updateContract(query)
            if (resp.code === 200) {
              this.addBudgetVisible = false
              this.$emit('handleChange')
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.budget-modal-container {
  height: 480px;
  overflow-y: auto;
  .search_item {
    width: 210px;
    margin-right: 10px;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
}

.divider {
  margin: 10px auto;
}
.add-wrapper,
.edit-wrapper {
  padding: 5px 20px;
  margin-bottom: 20px;
  .add-item,
  .edit-item {
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
    &:last-child {
      border: none;
    }
  }
  ::v-deep {
    .el-table__empty-block {
      border-bottom: 1px solid #EBEEF5;
    }
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-top: 10px;
  }
  .info-box {
    display: flex;
    justify-content: space-between;
    .copy {
      color: @primary-color;
      cursor: pointer;
      padding: 0 5px;
    }
  }
}
</style>
